// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/css/style.pcss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

// Components
import Rot13Email from './components/Rot13Email.vue';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination } from 'swiper';

const app = createApp({
    delimiters: [ '${', '}' ],

    components: {
        Swiper,
        SwiperSlide,
    },

    data() {
        return {
            swiperModules: [ Autoplay, Pagination ],
        };
    },
});

app.component('Rot13Email', Rot13Email);
app.mount('#app');
